import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/layout';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
	FacebookShareButton,
	TwitterShareButton,
	EmailShareButton,
	LinkedinShareButton
} from 'react-share';
import { Facebook, Twitter, Mail, Linkedin } from 'react-feather';

import ContactForm from '../components/ContactForm';
import { ServingCitiesText } from '../components/ServingCitiesText';

import Breadcrumbs from '../components/breadcrumbs';

import { Star } from 'react-feather';
import { TOTAL_REVIEW_COUNT, REVIEW_AVG } from '../consts';

import daysFromToday from '../helpers/daysFromToday';

// Function to get the last day of the current month
const getLastDayOfMonth = () => {
	const date = new Date();
	const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
	return lastDay.toLocaleDateString('en-US');
};

const ContactCard = styled.div`
	top: 30px !important;
	background: rgb(249 249 249);
`;

const BlogPostLayout = styled.div`
	background: #fff;
	.articleBody {
		max-width: 900px;
		margin: 0 auto;
		padding: 1em;
	}
`;

const Header = styled.div`
	text-align: left;
	margin: 0 auto;
	margin-bottom: 1em;
	border-bottom: 1px solid #eaeaea;
	h1 {
		font-size: calc(1.25vw + 1.25em);
		line-height: 1.15;
		font-weight: 800;
		color: #000;
	}
	border-bottom: 1px solid #f1f1f1;

	background: #f7f7f7;
`;

const BlogPostImage = styled.div`
	img {
		max-width: 900px;
		margn: 0 auto;
		text-align: center;
		border-radius: 0;
		margin-bottom: 1em;
		box-shadow: none !important;
	}
`;
const ShareThisPost = styled.div`
	p {
		font-weight: bold;
		margin: 0;
	}
	display: flex;
	align-items: center;
	.SocialMediaShareButton {
		display: flex;
		padding: 0 9px;
	}
	svg {
		stroke: none;
		fill: #888;
		cursor: pointer;
	}
	.stroke-white {
		stroke: white;
	}
`;

class OfferPostTemplate extends Component {
	render() {
		const post = this.props.data.contentfulOffer;
		const siteurl = this.props.data.site.siteMetadata.siteUrl;

		return (
			<Layout pageProps={this.props}>
				<div className="bg-white border-b border-gray-100">
					<nav
						className="flex max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3"
						aria-label="Breadcrumb"
					>
						<Breadcrumbs
							links={[
								{ to: '/', label: 'Home' },
								{ to: '/offers/', label: 'Offers' },
								{
									to: this.props.location.pathname,
									label: post.title
								}
							]}
						/>
					</nav>
				</div>
				<SEO
					title={post.metaTitle ? post.metaTitle : post.title}
					description={
						post.metaDescription ? post.metaDescription : post.title
					}
					img={post.image.gatsbyImageData.images.fallback.src}
					twitterCardContent={'summary_large_image'}
				/>
				<div className="relative">
					<article itemScope itemType="https://schema.org/Article">
						<div
							itemProp="publisher"
							itemScope
							itemType="https://schema.org/Organization"
							className="hidden"
						>
							<span itemProp="name" className="hidden">
								Bay Area Siding Company
							</span>
							<span
								itemProp="image"
								itemScope
								itemType="http://schema.org/ImageObject"
								className="hidden"
							>
								<img
									src="https://images.ctfassets.net/555y3d6wo2js/687cWoNXYdUvjoPIKLH6ko/513b022817506fe1203cbb6dd98a885e/solid-thumb.jpg"
									className="hidden"
									width="262"
									itemProp="url"
								/>
							</span>
							<span
								itemProp="logo"
								itemScope
								itemType="http://schema.org/ImageObject"
								className="hidden"
							>
								<img
									src="https://images.ctfassets.net/555y3d6wo2js/687cWoNXYdUvjoPIKLH6ko/513b022817506fe1203cbb6dd98a885e/solid-thumb.jpg"
									className="hidden"
									width="262"
									itemProp="url"
								/>
							</span>
						</div>
						<div className="max-w-7xl mx-auto px-4 md:px-6 flex flex-wrap py-10">
							<div className="w-full md:w-2/3 p-0 md:pr-16 relative articleBody z-1 prose">
								<div
									className="text-center md:text-left blogimgmw w-full relative"
									style={{ maxWidth: '900px' }}
								>
									<h1 className="my-2">{post.title}</h1>
									<div className="mb-6 mt-5">
										{post.excerpt.excerpt && (
											<span
												dangerouslySetInnerHTML={{
													__html:
														post.excerpt.excerpt +
														' Hurry, offer expires ' +
														getLastDayOfMonth()
												}}
											></span>
										)}
									</div>
									<div
										className="mx-auto overflow-hidden shadow-lg rounded-b-2xl relative z-1"
										style={{ marginBottom: '-70px' }}
									>
										<GatsbyImage
											image={getImage(post.image)}
											alt={post.title}
											style={{
												height: '400px',
												objectFit: 'cover',
												boxShadow:
													'1px 2px 8px 1px hsla(0,0%,0%,0)'
											}}
											className="blogPostImage rounded-t-2xl"
										/>
									</div>
									<div
										style={{ marginTop: '110px' }}
										dangerouslySetInnerHTML={{
											__html: post.body.body
										}}
									/>
								</div>
							</div>
							<div className="w-full md:w-1/3 p-0 md:pl-6">
								<div className="p-6 rounded-2xl z-1 bg-white shadow-lg sticky top-28">
									<h3 className="text-center md:text-left mb-3 font-display text-xl">
										Contact Bay Area Siding Company
									</h3>

									<ContactForm
										showLabels
										showPlaceHolders
										pageLocation={
											this.props.location.href
												? this.props.location.href
												: 'Not provided'
										}
									/>
								</div>
							</div>
						</div>
					</article>
				</div>
			</Layout>
		);
	}
}

export default OfferPostTemplate;

export const pageQuery = graphql`
	query OfferPostBySlug($slug: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		contentfulOffer(slug: { eq: $slug }) {
			id
			title
			slug
			excerpt {
				excerpt
			}
			body {
				body
			}
			image {
				gatsbyImageData(width: 1500, quality: 70, placeholder: NONE)
			}
			metaTitle
			metaDescription
			offerCode
			cardTitle
			cardDescription
			notificationBarActionText
		}
	}
`;
